import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var twoPoem = function twoPoem() {
  return import("./common/twoPoem");
};

var myFooter = function myFooter() {
  return import("./common/myFooter");
};

export default {
  components: {
    twoPoem: twoPoem,
    myFooter: myFooter
  },
  data: function data() {
    return {
      sayShow: false,
      sayContent: [{
        "talk": ["Hi, there👋", "这是一个 Vue2 Vue3 与 SpringBoot 结合的产物~"],
        "reply": ["然后呢？ 😃", "少废话！ 🙄"]
      }, {
        "talk": ["😘", "本站平时仅用于交流和学习新知识", "如涉及侵权请联系站长删除对应资源，谢谢！！！"],
        "reply": ["这个网站有什么用吗？ 😂"]
      }, {
        "talk": ["拥有自己的独立网站难道不酷吗🚀", "那就摸鱼吧👋", "摸鱼大军请在聊天室集合🥝"],
        "reply": []
      }],
      sayIndex: 0
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    var _this = this;

    setTimeout(function () {
      _this.sayShow = true;

      _this.say();
    }, 2000);
  },
  mounted: function mounted() {},
  methods: {
    answer: function answer(index, value) {
      var _this2 = this;

      $(".say-select").remove();
      var htmlStr = "<div class=\"say-right my-animation-slide-bottom\"><span class=\"say-item-right\">".concat(value, "</span></div>");
      var frag = document.createRange().createContextualFragment(htmlStr);
      document.getElementById("say-container").appendChild(frag);

      if (index === 0) {
        setTimeout(function () {
          _this2.say();
        }, 500);
      } else {
        var _htmlStr = "<div class=\"say-left my-animation-slide-bottom\"><span class=\"say-item-left\">\uD83D\uDC4B \uD83D\uDC4B \uD83D\uDC4B</span></div>";

        var _frag = document.createRange().createContextualFragment(_htmlStr);

        document.getElementById("say-container").appendChild(_frag);
      }
    },
    say: function say() {
      var _this3 = this;

      if (!this.$common.isEmpty(this.sayContent[this.sayIndex]) && !this.$common.isEmpty(this.sayContent[this.sayIndex].talk)) {
        this.sayContent[this.sayIndex].talk.forEach(function (value, index, talk) {
          setTimeout(function () {
            var htmlStr = "<div class=\"say-left my-animation-slide-bottom\"><span class=\"say-item-left\">".concat(value, "</span></div>");
            var frag = document.createRange().createContextualFragment(htmlStr);
            document.getElementById("say-container").appendChild(frag);

            if (talk.length === index + 1) {
              if (!_this3.$common.isEmpty(_this3.sayContent[_this3.sayIndex].reply)) {
                setTimeout(function () {
                  if (_this3.sayContent[_this3.sayIndex].reply.length === 2) {
                    var reply0 = _this3.sayContent[_this3.sayIndex].reply[0];
                    var reply1 = _this3.sayContent[_this3.sayIndex].reply[1];

                    var _htmlStr2 = "<div class=\"say-left my-animation-slide-bottom\"><span class=\"say-select\">".concat(reply0, "</span><span class=\"say-select\">").concat(reply1, "</span></div>");

                    var _frag2 = document.createRange().createContextualFragment(_htmlStr2);

                    document.getElementById("say-container").appendChild(_frag2);

                    document.getElementsByClassName("say-select")[0].onclick = function () {
                      _this3.answer(0, reply0);
                    };

                    document.getElementsByClassName("say-select")[1].onclick = function () {
                      _this3.answer(1, reply1);
                    };
                  } else if (_this3.sayContent[_this3.sayIndex].reply.length === 1) {
                    var _reply = _this3.sayContent[_this3.sayIndex].reply[0];

                    var _htmlStr3 = "<div class=\"say-left my-animation-slide-bottom\"><span class=\"say-select\">".concat(_reply, "</span></div>");

                    var _frag3 = document.createRange().createContextualFragment(_htmlStr3);

                    document.getElementById("say-container").appendChild(_frag3);

                    document.getElementsByClassName("say-select")[0].onclick = function () {
                      _this3.answer(0, _reply);
                    };
                  }

                  _this3.sayIndex += 1;
                }, 500);
              }
            }
          }, index * 500);
        });
      }
    }
  }
};